import React from 'react';
import {Layout} from '../modules/layout/containers/Layout';
import SEO from '../modules/layout/containers/SEO';
import {useSiteMetadata} from '../modules/common/hooks/useSiteMetadata';
import {computeSEOMeta} from '../modules/core/util/SEOUtil';

interface Props {
    location: Location;
}

const NotFoundPage: React.FC<Props> = ({location}) => {
    const siteMetadata = useSiteMetadata();
    const {title, metadata} = computeSEOMeta(siteMetadata, {} as any);
    return (
        <Layout location={location}>
            <SEO title={title} meta={metadata} />
            <h1>NOT FOUND</h1>
            <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        </Layout>
    );
};

export default NotFoundPage;
